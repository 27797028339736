import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';

import ScrollContainer from 'react-indiana-drag-scroll';
import { ListTitle } from '../ItemList/ListTitle';

import { ChevronIcon, ChevronIconDirection } from '../ChannelListHexagon/ChevronIcon/ChevronIcon';
import { VideoItem } from '../ItemList/VideoItem';

import './VideoListSlider.scss';

const ROW_ITEMS_COUNT = 8;

export const VideoListSlider = ({
	className,
	forwardedRef,
	isLoading,
	placeholderLength,
	title,
	videos,
}) => {
	const scrollContainer = useRef();

	const [isScrollable, setIsScrollable] = useState(
		{ right: true, left: true },
	);

	const videosOrPlaceholder = isLoading ? Array.from({ length: placeholderLength }) : videos;

	const { firstRowData, secondRowData } = useMemo(() => {
		if (!videosOrPlaceholder) return { firstRowData: [], secondRowData: [] };

		if (videosOrPlaceholder.length <= ROW_ITEMS_COUNT) {
			return { firstRowData: videosOrPlaceholder, secondRowData: [] };
		}

		// We separate the data into two rows by filtering the even and odd indices. In that way,
		// we can keep the same order of the original data on the two rows.
		return {
			firstRowData: videosOrPlaceholder?.filter((_, i) => i % 2 === 0),
			secondRowData: videosOrPlaceholder?.filter((_, i) => i % 2 === 1),
		};
	}, [videosOrPlaceholder]);

	const checkScroll = useCallback(() => {
		if (scrollContainer.current) {
			setIsScrollable({
				left: scrollContainer.current.scrollLeft > 0,
				right: (scrollContainer.current.scrollLeft + scrollContainer.current.clientWidth)
					< (scrollContainer.current.scrollWidth),
			});
		}
	}, []);

	useLayoutEffect(() => {
		window.addEventListener('resize', checkScroll);
		checkScroll();
		return () => window.removeEventListener('resize', checkScroll);
	}, [checkScroll]);

	const handleHorizontalScrollRight = () => {
		scrollContainer.current.scrollLeft += (scrollContainer.current.clientWidth);
	};

	const handleHorizontalScrollLeft = () => {
		scrollContainer.current.scrollLeft += (-scrollContainer.current.clientWidth);
	};

	if (videosOrPlaceholder.length === 0) return null;

	return (
		<section
			className={clsx('VideoListSlider ChannelList ChannelListHexagon size-lg', className)}
			ref={forwardedRef}
		>
			{title && <span className="d-inline-block ml-5"><ListTitle title={title} /></span>}
			<ScrollContainer
				className="ChannelListHexagon__scrollContainer content-white"
				innerRef={scrollContainer}
				onEndScroll={checkScroll}
				vertical={false}
				key="VideoScroll"
			>
				<div className="ChannelListHexagon_list">
					{firstRowData.map((video, i) => (
						<VideoItem
							className="px-4 mb-4"
							// eslint-disable-next-line react/no-array-index-key
							key={`${title}-video-list-1-${i}`}
							video={video}
						/>
					))}
					<div className="mr-5" />
				</div>
				<div className="ChannelListHexagon_list">
					{secondRowData.map((video, i) => (
						<VideoItem
							className="px-4 mb-4"
							// eslint-disable-next-line react/no-array-index-key
							key={`${title}-video-list-2-${i}`}
							video={video}
						/>
					))}
					<div className="mr-5" />
				</div>
			</ScrollContainer>
			{
				isScrollable.left && (
					<span
						className="ChannelList_chevron_left color-yellow"
						onClick={handleHorizontalScrollLeft}
					>
						<div className="position-absolute start-100 translate-middle">
							<ChevronIcon />
						</div>
					</span>
				)
			}
			{
				isScrollable.right && (
					<span
						className="ChannelList_chevron_right color-yellow"
						onClick={handleHorizontalScrollRight}
					>
						<div className="position-absolute translate-middle">
							<ChevronIcon direction={ChevronIconDirection.RIGHT} />
						</div>
					</span>
				)
			}
		</section>
	);
};

VideoListSlider.propTypes = {
	className: PropTypes.string,
	forwardedRef: PropTypes.shape({}),
	isLoading: PropTypes.bool,
	placeholderLength: PropTypes.number,
	title: PropTypes.node,
	videos: PropTypes.arrayOf(PropTypes.shape({})),
};

VideoListSlider.defaultProps = {
	className: '',
	forwardedRef: null,
	isLoading: false,
	placeholderLength: 16,
	title: null,
	videos: [],
};
