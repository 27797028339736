/* eslint-disable react/prop-types */
// @ts-check

import { useEffect } from 'react';
import { FormGroup, Input, Label, Alert } from 'reactstrap';
import { Form, Field, FieldError } from 'react-jsonschema-form-validation';
import { Link, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import FormSubmit from '../Form/Submit';
import { ButtonPillOutline } from '../Button/Pill';
import { ResourceAccessCard } from './Card';
import { getLink, Path } from '../../RoutePath';

const schema = {
	type: 'object',
	additionalProperties: false,
	properties: {
		password: {
			type: 'string',
			minLength: 6,
		},
	},
	required: [
		'password',
	],
};

/**
 * @param {string} text
 * @returns {string}
 */
const cleanPassword = (text) => text.replace(/[^0-9]/g, '');

/**
 * @typedef {{
 *  lastConfirmedPassword?: string,
 * 	onConfirm: (password: string) => void,
 *  password: string,
 *  setPassword: (password: string) => void,
 * }} ResourceAccessEnterPasswordProps
 */

/** @type {React.FC<ResourceAccessEnterPasswordProps>} */
export const ResourceAccessEnterPassword = ({
	lastConfirmedPassword,
	onConfirm,
	password,
	setPassword,
}) => {
	const { t } = useTranslation();

	const [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {
		if (searchParams.has('pwd')) {
			const pwd = searchParams.get('pwd');
			if (!pwd) return;

			setPassword(pwd);
			setSearchParams((prev) => {
				prev.delete('pwd');
				return prev;
			});
			onConfirm(pwd);
		}
	}, [searchParams, setSearchParams, setPassword, onConfirm]);

	const isPasswordIncorrect = lastConfirmedPassword === password;

	return (
		<ResourceAccessCard>
			<h3>{t('RessourceAccess.EnterPassword.private')}</h3>
			<p>{t('RessourceAccess.EnterPassword.typePassword')}</p>
			<Form
				className="text-left"
				data={{ password }}
				schema={schema}
				onSubmit={() => onConfirm(password)}
			>
				<FormGroup>
					<Label className="text-secondary">{t('RessourceAccess.EnterPassword.password')}</Label>
					<Field
						component={Input}
						name="password"
						value={password}
						onChange={(
							/** @type {React.ChangeEvent<HTMLInputElement>} */e,
						) => setPassword(cleanPassword(e.target.value))}
						type="text"
						maxLength="6"
						placeholder={t('RessourceAccess.EnterPassword.password')}
					/>
					<FieldError name="password" />
				</FormGroup>
				{isPasswordIncorrect && (
					<Alert color="danger">
						{t('RessourceAccess.EnterPassword.incorrectPassword')}
					</Alert>
				)}
				<footer className="mt-4 mx-auto d-flex">
					<ButtonPillOutline
						color="secondary"
						className="mr-2"
						tag={Link}
						to={getLink(Path.ROOT)}
					>
						{t('RessourceAccess.EnterPassword.cancel')}
					</ButtonPillOutline>
					<FormSubmit className="btn-pill shadow-none btn btn-primary ml-auto">
						{t('RessourceAccess.EnterPassword.verify')}
					</FormSubmit>
				</footer>
			</Form>
		</ResourceAccessCard>
	);
};
