// @ts-check

/** @enum {typeof ResourceAccessRole[keyof typeof ResourceAccessRole]} */
export const ResourceAccessRole = /** @type {const} */({
	PUBLIC: 'PUBLIC',
	VIEWER: 'VIEWER',
	PARTICIPANT: 'PARTICIPANT',
	MODERATOR: 'MODERATOR',
	OPERATOR: 'OPERATOR',
	OWNER: 'OWNER',
});
