// @ts-check

import { createContext, useContext } from 'react';

/**
 * @import { IAuctionDto } from '../../api/channel/auction.dto';
 */

/**
 * @typedef {{
 * activeAuction: IAuctionDto | undefined,
 * handleEventAuction: (newAuction: IAuctionDto) => void,
 * setActiveAuction: React.Dispatch<React.SetStateAction<IAuctionDto | undefined>>
* }} IAuctionContext
*/

export const AuctionContext = createContext(/** @type {IAuctionContext} */({}));

export const useAuction = () => useContext(AuctionContext);
