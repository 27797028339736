/* eslint-disable react/prop-types */
// @ts-check

import { useCallback, useEffect, useMemo, useState } from 'react';

import { AuctionContext } from './Context';
import { useStudio } from '../Studio/Context';
import { ResourceAccessRole } from '../../lib/ResourceAccessRole';

/**
 * @import { IAuctionDto } from '../../api/channel/auction.dto';
 * @import { IAuctionContext } from './Context';
 */

/** @enum {typeof AuctionStatus[keyof typeof AuctionStatus]} */
export const AuctionStatus = /** @type {const} */({
	ACTIVE: 'ACTIVE',
	FINISHED: 'FINISHED',
});

/** @enum {typeof AuctionPosition[keyof typeof AuctionPosition]} */
export const AuctionPosition = /** @type {const} */({
	TOP_LEFT: 'TOP_LEFT',
	TOP_RIGHT: 'TOP_RIGHT',
	CENTER: 'CENTER',
	BOTTOM_LEFT: 'BOTTOM_LEFT',
	BOTTOM_RIGHT: 'BOTTOM_RIGHT',
});

/**
 * @typedef {{
 *  children: React.ReactNode,
 *  studioRole: ResourceAccessRole,
 * }} AuctionProviderProps
 */

/** @type {React.FC<AuctionProviderProps>} */
export const AuctionProvider = ({ children, studioRole }) => {
	const { activeContent, setActiveContent } = useStudio();

	const [activeAuction, setActiveAuction] = useState(
		/** @type {IAuctionContext['activeAuction']} */(undefined),
	);

	const handleEventAuction = useCallback(
		/** @type {IAuctionContext['handleEventAuction']} */(newAuction) => {
			/** @type {ResourceAccessRole[]} */
			const alwaysShowAuctionRoles = [ResourceAccessRole.OWNER, ResourceAccessRole.OPERATOR];

			if (
				alwaysShowAuctionRoles.includes(studioRole)
				|| newAuction?.status === AuctionStatus.ACTIVE
			) setActiveAuction(newAuction);
			else setActiveAuction(undefined);
		}, [studioRole],
	);

	// Set active auction when joining studio
	useEffect(() => {
		if (activeContent?.activeAuction) {
			handleEventAuction(activeContent.activeAuction);
			setActiveContent((state) => ({
				...state,
				activeAuction: null,
			}));
		}
	}, [activeContent?.activeAuction, handleEventAuction, setActiveContent]);

	const value = useMemo(() => ({
		activeAuction,
		handleEventAuction,
		setActiveAuction,
	}), [
		activeAuction,
		handleEventAuction,
		setActiveAuction,
	]);

	return (
		<AuctionContext.Provider value={value}>
			{children}
		</AuctionContext.Provider>
	);
};
