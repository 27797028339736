// @ts-check

import { createContext, useContext } from 'react';

/**
 * @import { SoupSession } from '@technomiam/soup-client';
 * @import { STREAM_TYPE_LIVE, STREAM_TYPE_PREVIEW } from '../../lib/stream';
 * @import { SourceParticipantOfferDevice } from '../SourceParticipantOffers/Context';
 */

/**
 * @typedef {{
 * 	configId?: number,
 * 	computerId?: string,
 *	device?: SourceParticipantOfferDevice,
 *	streamType?: STREAM_TYPE_LIVE | STREAM_TYPE_PREVIEW,
 *	peerExceptions?: string[],
 *	peerReceiver?: string,
 *	alphaColor?: {
 *		color: {
 *			r: number,
 *			g: number,
 *			b: number,
 *		},
 *		sensitivity: number,
 *	}?,
 *	preventLarsens?: boolean,
 *	talkback?: { senderUserId: string, receiverUserIds: string[] }
 *	trackId?: MediaStreamTrack['id'],
 *	user: {
 *		avatar?: string,
 *		nickname: string,
 *		userId: string
 *	},
 * }} AppData
 */

/**
 * Represents a published media (video or audio) with its metadata.
 *
 * @typedef {{
 * 	appData: AppData,
 * 	kind: 'audio' | 'video',
 * 	peerId: string,
 * 	producerId: string,
 *  roomId: string,
 * }} Publication
 */

/**
 * @typedef {{
 *	alphaColor?: {
 * 		color: {
 * 			r: number,
 * 			g: number,
 * 			b: number,
 * 		},
 * 		sensitivity: number,
 * 	}?,
 *  computerId: string,
 *  configId?: number,
 *  device?: SourceParticipantOfferDevice,
 *	preventLarsens?: boolean,
 *  sourceStreamType?: string,
 *	talkback?: { senderUserId: string, receiverUserIds: string[] }
 *  trackId: MediaStreamTrack['id'],
 *	user: {
 *  	avatar?: string,
 *  	nickname: string,
 *  	userId: string,
 *  },
 * }} PublishAppData
 */
/**
* Represents a subscribed publication, with the media stream track available for consumption.
*
* @typedef {Publication & {
* 	mediaStreamTrack: MediaStreamTrack
* }} Subscription
*/

/**
 * @typedef {{
 * 	spatialLayer: number,
 * }} PreferredLayers
 */

/**
 * @typedef {{
 *  publication: Publication,
 *  preferredLayers?: PreferredLayers,
 * }} SubscriptionRequest
 */

/**
 * @typedef {{
 *  join: (roomIds: string[]) => void,
 *  leave: (roomIds?: string[]) => void,
 *  publications: Publication[],
 *  roomIds: string[],
 *  soupSession: SoupSession | undefined,
 * }} ISoupContext
 */

export const SoupContext = createContext(
	/** @type {ISoupContext | undefined} */(undefined),
);

/**
 * @returns {ISoupContext}
 */
export const useSoup = () => {
	const sourcesContext = useContext(SoupContext);
	// type guard (removes undefined type)
	if (!sourcesContext) {
		throw new Error('useSoup must be used within a Soup');
	}
	return sourcesContext;
};

/**
 * @return {ISoupContext['soupSession']}
 */
export const useSoupSession = () => {
	const { soupSession } = useSoup();
	return soupSession;
};
