// @ts-check

import { useMemo, useRef } from 'react';

/** @import { LocalMediaStreamTrack, MediaAudioTrackControllable } from './types' */

const EQUALIZER_BANDS_HZ = [
	32,
	64,
	125,
	500,
	2000,
	4000,
	8000,
	16000,
];

/**
 * @template {LocalMediaStreamTrack} T
 * @param {T} track
 * @returns {MediaAudioTrackControllable<T>}
 */
const createAudioControllableTrack = (track) => {
	const audioContext = new AudioContext();
	const stream = new MediaStream([track]);
	const source = audioContext.createMediaStreamSource(stream);
	const destination = audioContext.createMediaStreamDestination();

	/** @type {BiquadFilterNode[]} */
	const equalizers = [];
	const panner = audioContext.createStereoPanner();
	panner.pan.value = 0;

	EQUALIZER_BANDS_HZ.forEach((freq) => {
		const filter = audioContext.createBiquadFilter();
		filter.type = 'peaking';
		filter.frequency.value = freq;
		filter.Q.value = 1;
		filter.gain.value = 0;
		equalizers.push(filter);
	});
	equalizers.reduce((prev, curr) => {
		prev.connect(curr);
		return curr;
	});

	source.connect(panner);
	panner.connect(equalizers[0]);
	equalizers[equalizers.length - 1].connect(destination);

	const outputTrack = /** @type {MediaAudioTrackControllable<T>} */(
		destination.stream.getAudioTracks()[0]
	);

	outputTrack.configId = track.configId;
	//outputTrack.label = track.label;
	outputTrack.device = track.device;
	outputTrack.physicalDeviceId = track.physicalDeviceId;
	outputTrack.sourceOffer = track.sourceOffer;
	outputTrack.panner = panner;
	outputTrack.equalizers = equalizers;
	outputTrack.originalTrackId = track.id;

	return outputTrack;
};

/**
 * @template {LocalMediaStreamTrack} T
 * @param {T[]} originalTracks
 * @returns {MediaAudioTrackControllable<T>[]}
 */
export const useMediaAudioTracksControllable = (originalTracks) => {
	const newTracksRef = useRef(
		/** @type {Record<string, MediaAudioTrackControllable<T>>} */({}),
	);

	const newTracks = useMemo(() => {
		// Compute track to add / remove
		const tracksToAdd = originalTracks.filter((track) => !newTracksRef.current[track.id]);
		const tracksToRemove = Object.values(newTracksRef.current)
			.filter((controllableTrack) => !originalTracks
				.find((t) => t.id === controllableTrack.originalTrackId));

		// Add new tracks
		tracksToAdd.forEach((originalTrack) => {
			const newTrack = createAudioControllableTrack(originalTrack);
			newTracksRef.current[originalTrack.id] = newTrack;
		});

		// Remove old tracks
		tracksToRemove.forEach((controllableTrack) => {
			controllableTrack.stop();
			delete newTracksRef.current[controllableTrack.originalTrackId];
		});

		return Object.values(newTracksRef.current);
	}, [originalTracks]);

	return useMemo(() => newTracks, [newTracks]);
};
