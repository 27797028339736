// @ts-check

import { createContext, useContext } from 'react';

/**
 * @import { UseAsyncReturn } from 'react-async-hook';
 * @import { IVoteDto, IVoteTemplateDto, IVotePublishDto } from '../../api/channel/vote.dto';
 * @import { PaginatedResult } from '../../api/helpers';
 * @import { ListSortingType } from '../ListSorting/Context';
 */

/**
* @typedef {IVoteDto & {
*   playlistInfo?: {
*   	position: number,
*  		total: number,
* }}} IVoteDtoWithPlaylistInfo
*/

/** @enum {typeof VoteAudienceType[keyof typeof VoteAudienceType]} */
export const VoteAudienceType = /** @type {const} */({
	PARTICIPANT: 'PARTICIPANT',
	VIEWER: 'VIEWER',
});

export const VIEWER_DELAY = 30; // In seconds

/** @enum {typeof VoteReportRecipientType[keyof typeof VoteReportRecipientType]} */
export const VoteReportRecipientType = /** @type {const} */({
	OWNER: 'OWNER',
	OPERATOR: 'OPERATOR',
	PARTICIPANT: 'PARTICIPANT',
	VIEWER: 'VIEWER',
});

/**
 * @import { createVotes, createVoteTemplate, sendReportThroughMail } from '../../api/channel/vote';
 * @typedef {Parameters<typeof createVotes>} APICreateVotesParams
 * @typedef {Parameters<typeof createVoteTemplate>} APICreateVoteTemplateParams
 * @typedef {Parameters<typeof sendReportThroughMail>} APISendReportThroughMailParams
 */

/**
 * @typedef {{
 * 	activeVote: IVoteDto | undefined,
 * 	createVotes: (
 * 		studioId: APICreateVotesParams[0],
 * 		votesData: APICreateVotesParams[1],
 * 		optionalParameters: APICreateVotesParams[2],
 * 	) => Promise<void>,
 * 	createVoteTemplate: (voteTemplate: APICreateVoteTemplateParams[0]) => Promise<void>,
 * 	deleteVoteTemplate: (voteTemplateId: string) => Promise<void>,
 * 	endVote: (voteId: string) => Promise<void>,
 * 	endVotePublish: (voteId: string) => Promise<void>,
 * 	fetchReport: (studioId: string) => Promise<void>,
 * 	fetchReportCSV: (studioId: string, audienceType: VoteAudienceType) => Promise<void>,
 * 	fetchReportCSVAsync: UseAsyncReturn<void, Parameters<IVoteContext['fetchReportCSV']>>,
 * 	fetchVotes: (studioId: string) => Promise<void>,
 * 	fetchVoteTemplates: (
 * 		isArchived: boolean,
 * 		itemsPerPage?: number,
 * 		currentPage?: number,
 * 		currentSort?: ListSortingType,
 * 	) => Promise<void>,
 * 	handleEventVoteActiveUpdate: (updatedVote: IVoteDto) => void,
 * 	handleEventVotesActiveNew: (newActiveVotes: IVoteDto[]) => void,
 * 	previewableVote: IVoteDtoWithPlaylistInfo | undefined,
 * 	publishVote: (voteId: string) => Promise<void>,
 * 	report: {
 * 		[VoteAudienceType.PARTICIPANT]: IVotePublishDto[],
 * 		[VoteAudienceType.VIEWER]: IVotePublishDto[]
 * 	} | undefined,
 * 	restoreVoteTemplate: (voteTemplateId: string) => Promise<void>,
 * 	sendReportThroughMail: (
 * 		studioId: APISendReportThroughMailParams[0],
 * 		content: APISendReportThroughMailParams[1],
 * 	) => Promise<void>,
 * 	sendReportThroughMailAsync: UseAsyncReturn<
 * 		void, Parameters<IVoteContext['sendReportThroughMail']>
 * 	>,
 * 	setViewerDelay: React.Dispatch<React.SetStateAction<number>>
 * 	viewerDelay: number,
 * 	voteOnVote: (voteId: string, answerId: string, isGuest?: boolean) => Promise<void>,
 * 	votes: IVoteDto[] | undefined,
 * 	voteTemplates: PaginatedResult<IVoteTemplateDto> | undefined,
 * }} IVoteContext
 */

export const VoteContext = createContext(/** @type {IVoteContext | undefined} */(undefined));

export const useVote = () => {
	const voteContext = useContext(VoteContext);
	// type guard (removes undefined type)
	if (!voteContext) {
		throw new Error('useVote must be used within a VoteProvider');
	}
	return voteContext;
};
