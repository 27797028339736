/* eslint-disable react/prop-types */
//@ts-check
import { useCallback, useMemo, useState, createContext, useContext } from 'react';

/**
 * @import { ChannelMixerStatus } from '../../ReactVideo/Mixing';
 */

/**
 * @typedef {{
 * 	filters: {
 * 		balance: number,
 * 		equalizer: { value: number, label: string, freq: number }[],
 * 	},
 * 	source: { id: string, type: 'participant' },
 * }} AudioFiltersConfig
 */

/**
 * @typedef {{
 * audioFiltersConfig: AudioFiltersConfig[],
 * handleCMSEvents: (cms: ChannelMixerStatus) => void,
 * }} IAudioFiltersContext
 */

const AudioFiltersContext = createContext(/** @type {IAudioFiltersContext} */({}));

export const useMediaAudioFilters = () => useContext(AudioFiltersContext);

/**
 * @typedef {{
 * 	children?: React.ReactNode,
 * }} MediaAudioFiltersProviderProps
 */

export const MediaAudioFiltersProvider = (
	/** @type {MediaAudioFiltersProviderProps} */
	{
		children,
	},
) => {
	const [audioFiltersConfig, setAudioFiltersConfig] = useState(
		/** @type {AudioFiltersConfig[]} */([]),
	);

	const handleCMSEvents = useCallback(
		(/** @type {ChannelMixerStatus} */ cms) => {
			const { audioFilters } = cms;
			setAudioFiltersConfig(audioFilters || []);
		}, [],
	);

	const context = useMemo(() => ({
		audioFiltersConfig,
		handleCMSEvents,
	}), [
		audioFiltersConfig,
		handleCMSEvents,
	]);

	return (
		<AudioFiltersContext.Provider value={context}>
			{children}
		</AudioFiltersContext.Provider>
	);
};
