/* eslint-disable react/prop-types */
// @ts-check

import BaseSkeleton from 'react-loading-skeleton';

import { ScreenThemeLayout, useScreenTheme } from '../Studio/ScreenTheme/Provider';

import 'react-loading-skeleton/dist/skeleton.css';

// Base colors are the same as the theme colors
const BaseColorNameToHex = {
	[ScreenThemeLayout.DARK]: {
		white: '#ffffff', // This color doesn't depend on current theme
		light: '#1c3144',
		dark: '#172938',
		darker: '#12202c',
		black: '#12202c', // This color doesn't depend on current theme
	},
	[ScreenThemeLayout.LIGHT]: {
		white: '#ffffff', // This color doesn't depend on current theme
		light: '#ffffff',
		dark: '#f5f5f5',
		darker: '#eeeeee',
		black: '#12202c', // This color doesn't depend on current theme
	},
};

// Slighly different colors for the highlights
const HighlightColorNameToHex = {
	[ScreenThemeLayout.DARK]: {
		white: '#f5f5f5', // This color doesn't depend on current theme
		light: '#20374d', // 3% lighter than the base color
		dark: '#1b3041', // 3% lighter than the base color
		darker: '#162736', // 3% lighter than the base color
		black: '#162736', // This color doesn't depend on current theme
	},
	[ScreenThemeLayout.LIGHT]: {
		white: '#f5f5f5', // This color doesn't depend on current theme
		light: '#f5f5f5', // 4% darker than the base color
		dark: '#eeeeee', // 3% darker than the base color
		darker: '#e9e9e9', // 2% darker than the base color
		black: '#162736', // This color doesn't depend on current theme
	},
};

/**
 * @typedef {import('react-loading-skeleton').SkeletonProps & {
 *  color?: 'white' | 'light' | 'dark' | 'darker' | 'black',
 * }} ContentLoaderProps
 */

export const ContentLoader = (
	/** @type {ContentLoaderProps} */
	{
		color = 'light',
		...props
	},
) => {
	const { currentScreenTheme } = useScreenTheme();

	return (
		<BaseSkeleton
			baseColor={BaseColorNameToHex[currentScreenTheme][color]}
			highlightColor={HighlightColorNameToHex[currentScreenTheme][color]}
			{...props}
		/>
	);
};
