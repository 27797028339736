/* eslint-disable react/prop-types */
// @ts-check
import { createContext, useContext, useMemo } from 'react';

/** @import { AuthenticatedUser } from './ReactVideo'; */

/**
 * @typedef {{
 * 	getConnectionConfig: () => Promise<import('../../api/soup').ConnectionConfig>,
 * 	isController: boolean,
 *  roomId: string,
 * 	user: AuthenticatedUser,
 * }} IReactVideoContext
 */

export const ReactVideoContext = createContext(/** @type {IReactVideoContext} */({}));

export const useReactVideo = () => useContext(ReactVideoContext);

/**
 * @typedef {{
 * 	children: React.ReactNode,
 * 	getConnectionConfig: IReactVideoContext['getConnectionConfig'],
 * 	isController?: IReactVideoContext['isController'],
 *  roomId: IReactVideoContext['roomId'],
 * 	user: IReactVideoContext['user'],
 * }} ReactVideoProviderProps
 */

/** @type {React.FC<ReactVideoProviderProps>} */
export const ReactVideoProvider = ({
	children,
	getConnectionConfig,
	isController = false,
	roomId,
	user,
}) => {
	const contextValue = useMemo(() => ({
		getConnectionConfig,
		isController,
		roomId,
		user,
	}), [
		getConnectionConfig,
		isController,
		roomId,
		user,
	]);

	return (
		<ReactVideoContext.Provider value={contextValue}>
			{children}
		</ReactVideoContext.Provider>
	);
};
