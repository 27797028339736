/* eslint-disable react/prop-types */
// @ts-check

/**
 * This file allow to share images locally within react-video. The component, MediaShareImage,
 * supports various image formats including JPEG, PNG, WebP, BMP, and GIF.
 *
 * Special handling is implemented for GIF files because they cannot be directly drawn on a canvas
 * element. Instead, GIF frames are expanded into a spritesheet, which is a single image containing
 * all frames of the GIF laid out side-by-side. When drawing the image on the canvas, the correct
 * frame is selected based on the current time, allowing for the animated playback of the GIF.
 *
 * The static images are drawn directly on the canvas element.
 * */

import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { GifReader } from 'omggif';

import { stopTrack, MediaShareVideoType } from '../utils';
import { SourceParticipantOfferType, useSourceParticipantOffers } from '../../SourceParticipantOffers/Context';
import { getComputerId } from '../../../lib/computerId';
import { useMediaTracksManager } from '../Tracks/Manager/useMediaTracksManager';

/**
 * @import {
 * 	SourceParticipantOfferFileDeviceKind,
 *  SourceParticipantOfferTrack,
 * } from '../../SourceParticipantOffers/Context';
 * @import { MediaTracksManager } from '../Tracks/Manager/useMediaTracksManager';
 */

/**
 * @typedef {SourceParticipantOfferTrack<
 * typeof SourceParticipantOfferType.IMAGESHARE> & {
 * 	firefoxFixCaptureSettings?: { width: number, height: number },
 * }} MediaStreamTrackImage
 */

/**
 * @typedef {MediaStream} MediaStreamImage
 */

const MAX_FPS = 1;
const Perf = performance || Date;
const DEFAULT_CONFIG_ID = 0;

export const MediaShareImageParams = {
	ACCEPTED_FORMATS: ['image/jpeg', 'image/webp', 'image/png', 'image/gif', 'image/bmp'],
};

/**
* @typedef {{
* 	name: string,
* 	height: number,
*  	width: number,
* 	src: string,
* }} ImageShareData
*/

/**
 * @typedef {{
 * imageshareActive: boolean,
 * imageshareActiveTracks: MediaStreamTrackImage[],
 * imageshareData: ImageShareData?,
 * imageshareMediastream?: MediaStreamImage,
 * imageshareRequestError: Error?,
 * requestImageshare: (requestImageshareFile: File) => Promise<void>
 * setImageshareRequestError: (error: Error?) => void
 * stopImageshare: () => void
* }} IMediaShareImageContext
*/

const MediaShareImageContext = createContext(/** @type {IMediaShareImageContext} */({}));

export const useMediaShareImage = () => useContext(MediaShareImageContext);

/**
 * @param {File} file
 * @returns {Promise<GifReader?>}
 */
const getGifReader = async (file) => {
	try {
		const arrayBuffer = await new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = (e) => {
				if (e.target?.result instanceof ArrayBuffer) resolve(e.target.result);
				else reject(new Error('Invalid file'));
			};
			reader.onerror = reject;
			reader.readAsArrayBuffer(file);
		});
		const buffer = new Uint8Array(arrayBuffer);
		return new GifReader(buffer);
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error('Error reading the file:', error);
	}
	return null;
};

/**
 * @typedef {{
 * 		canvasUrl: string,
* 		frameCount: number,
* 		frameWidth: number,
* 		frameHeight: number,
* 		frameDelays: number[],
* 		frameDisposals: number[],
* }} GifSpritesheetInfo
 */

/**
 * @param {File} file
 * @returns {Promise<GifSpritesheetInfo>}
 */
const getGifAsSpritesheet = async (file) => {
	const gif = await getGifReader(file);
	if (!gif) throw new Error('Invalid GIF file');

	const canvas = document.createElement('canvas');
	const context = canvas.getContext('2d');
	if (!context) throw new Error('Canvas not supported');
	const frameCount = gif.numFrames();
	const frameWidth = gif.width;
	const frameHeight = gif.height;

	canvas.width = frameWidth * frameCount;
	canvas.height = frameHeight;

	for (let i = 0; i < frameCount; i += 1) {
		const imageData = context.createImageData(frameWidth, frameHeight);
		gif.decodeAndBlitFrameRGBA(i, imageData.data);
		context.putImageData(imageData, i * frameWidth, 0);
	}

	return {
		canvasUrl: canvas.toDataURL(),
		frameCount,
		frameWidth,
		frameHeight,
		frameDelays: Array.from({ length: frameCount }, (_, i) => gif.frameInfo(i).delay * 10),
		frameDisposals: Array.from({ length: frameCount }, (_, i) => gif.frameInfo(i).disposal),
	};
};

/**
 * @param {MediaStreamTrack} track
 * @returns {SourceParticipantOfferFileDeviceKind}
 */
const getFileKindFromTrack = (track) => {
	if (track.kind === 'audio') return 'audiofile';
	if (track.kind === 'video') return 'videofile';
	throw new Error(`Unknown kind '${track.kind}'`);
};

/**
 * @typedef {{
 * 	activeShareType: MediaShareVideoType?,
 * 	children: React.ReactNode,
 * 	disabled?: boolean,
 * 	isHost?: boolean,
 * 	onShare: (shareType: MediaShareVideoType) => void,
 * }} MediaShareImageProps
 */

export const MediaShareImage = (
	/** @type {MediaShareImageProps} */
	{
		activeShareType,
		children,
		disabled = false,
		isHost = false,
		onShare,
	},
) => {
	const { createOrUpdateSource, removeSourceById } = useSourceParticipantOffers();
	const [imageshareData, setImageshareData] = useState(
		/** @type {IMediaShareImageContext['imageshareData']} */(null),
	);

	const imageRef = useRef(/** @type {HTMLImageElement?} */(null));

	/** @type {MediaTracksManager<IMediaShareImageContext['imageshareActiveTracks'][number]>} */
	const {
		addTracks,
		clearTracks,
		tracks: imageshareActiveTracks,
	} = useMediaTracksManager();

	const [imageshareRequestError, setImageshareRequestError] = useState(
		/** @type {IMediaShareImageContext['imageshareRequestError']} */(null),
	);

	const imageshareActive = imageshareActiveTracks.length > 0;

	const imageshareMediastream = useMemo(() => {
		if (imageshareActiveTracks.length > 0) {
			const mediastream = new MediaStream(imageshareActiveTracks);
			// Refresh mediastream when tracks change to avoid player image stuck
			return mediastream;
		}
		return undefined;
	}, [imageshareActiveTracks]);

	const isAllowed = (isHost || activeShareType === MediaShareVideoType.IMAGE);

	const requestedImageshareFile = useRef(/** @type {File?} */(null));

	const requestImageshare = useCallback(
		/** @type {IMediaShareImageContext['requestImageshare']} */
		async (requestImageshareFile) => {
			requestedImageshareFile.current = requestImageshareFile;

			try {
				setImageshareRequestError(null);

				// At the moment animated png and animated webp are not supported
				const isGif = requestImageshareFile.type === 'image/gif';
				/** @type {GifSpritesheetInfo?} */
				let gifInfo = null;
				if (isGif) gifInfo = await getGifAsSpritesheet(requestImageshareFile);

				const image = document.createElement('img');
				imageRef.current = image;
				image.src = gifInfo
					? gifInfo.canvasUrl
					: URL.createObjectURL(requestImageshareFile);

				await new Promise((resolve) => {
					image.onload = resolve;
				});

				setImageshareData({
					name: requestImageshareFile.name,
					height: gifInfo ? gifInfo.frameHeight : image.height,
					width: gifInfo ? gifInfo.frameWidth : image.width,
					src: gifInfo ? URL.createObjectURL(requestImageshareFile) : image.src,
				});

				if (!requestedImageshareFile.current) return;

				const canvas = document.createElement('canvas');
				const context = canvas.getContext('2d');
				if (!context) throw new Error('Canvas not supported');

				canvas.width = gifInfo ? gifInfo.frameWidth : image.width;
				canvas.height = gifInfo ? gifInfo.frameHeight : image.height;

				let lastRender = Perf.now();
				let lastGifFrame = -1;
				const interval = 1000 / (gifInfo ? gifInfo.frameCount : MAX_FPS);
				const mediastream = (
					/**
					 * @type {Omit<MediaStream, 'getVideoTracks'> & {
					 * 	requestFrame?: () => void,
					 *  getVideoTracks: () => CanvasCaptureMediaStreamTrack[],
					 * }}
					 */
					(canvas.captureStream(0))
				);

				const animation = () => {
					if (!requestedImageshareFile.current) return;

					requestAnimationFrame(animation);

					const now = Perf.now();
					const delta = now - lastRender;
					if (gifInfo) {
						// GIF animation
						if (lastGifFrame === -1) lastGifFrame = 0;
						else {
							const lastFrameDelay = gifInfo.frameDelays[lastGifFrame];
							if (delta < lastFrameDelay) return;
							lastGifFrame = (lastGifFrame + 1) % gifInfo.frameCount;
							lastRender = now - (delta % lastFrameDelay);
						}
					} else {
						if (delta < interval) return;
						lastRender = now - (delta % interval);
					}

					if (gifInfo) {
						// Frame disposals indicate how the current frame should be disposed
						// before rendering the next one:
						// - 0: no disposal specified
						// - 1: do not dispose
						// - 2: restore to background color
						// - 3: restore to previous
						const clearBeforeDraw = gifInfo.frameDisposals[lastGifFrame] === 2;
						const isLastFrame = lastGifFrame === gifInfo.frameCount - 1;
						if (clearBeforeDraw && !isLastFrame) {
							context.clearRect(0, 0, canvas.width, canvas.height);
						}

						context.drawImage(
							image,
							lastGifFrame * gifInfo.frameWidth,
							0,
							gifInfo.frameWidth,
							gifInfo.frameHeight,
							0,
							0,
							gifInfo.frameWidth,
							gifInfo.frameHeight,
						);
					} else context.drawImage(image, 0, 0, image.width, image.height);

					// mediastream will not contain the same object depending on the web browser
					// - Firefox: CanvasCaptureMediaStream
					// - Chrome: MediaStream
					if (mediastream.requestFrame) mediastream.requestFrame();
					else mediastream.getVideoTracks()[0].requestFrame();
				};
				requestAnimationFrame(animation);

				const sourceOffer = {
					computerId: getComputerId(),
					configId: 0,
					id: `${getComputerId()}:0:imageshare`,
					label: requestImageshareFile.name,
					subType: SourceParticipantOfferType.IMAGESHARE,
				};

				const imageTracks = /** @type {MediaStreamTrackImage[]} */(mediastream.getTracks()).map((
					imageTrack,
				) => {
					imageTrack.configId = DEFAULT_CONFIG_ID;
					imageTrack.device = {
						deviceId: `${getComputerId()}:imageshare`,
						kind: getFileKindFromTrack(imageTrack),
						label: 'Imageshare',
					};
					// Firefox MediaStreamTrack.getSettings returns an empty object in case of captureStream
					imageTrack.firefoxFixCaptureSettings = {
						width: canvas.width,
						height: canvas.height,
					};
					imageTrack.sourceOffer = sourceOffer;

					return imageTrack;
				});
				createOrUpdateSource({
					...sourceOffer,
					devices: imageTracks.map((track) => track.device),
				});

				addTracks(imageTracks);
				onShare(MediaShareVideoType.IMAGE);
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error(error);
				setImageshareRequestError(/** @type {Error} */(error));
			}
		}, [createOrUpdateSource, onShare, addTracks],
	);

	const stopImageshare = useCallback(
		/** @type {IMediaShareImageContext['stopImageshare']} */
		() => {
			setImageshareRequestError(null);
			setImageshareData(null);

			requestedImageshareFile.current = null;

			imageRef.current = null;

			imageshareActiveTracks.forEach(stopTrack);
			clearTracks();
			removeSourceById(`${getComputerId()}:0:imageshare`);
		}, [imageshareActiveTracks, removeSourceById, clearTracks],
	);

	useEffect(() => {
		const shouldStopImageshare = requestedImageshareFile.current && (disabled || !isAllowed);
		if (shouldStopImageshare) stopImageshare();
	}, [disabled, isAllowed, stopImageshare]);

	const imageshareActiveTracksRef = useRef(imageshareActiveTracks);
	useEffect(
		() => { imageshareActiveTracksRef.current = imageshareActiveTracks; },
		[imageshareActiveTracks],
	);

	// cleanup
	useEffect(() => () => {
		requestedImageshareFile.current = null;
		imageRef.current = null;
		imageshareActiveTracksRef.current.forEach(stopTrack);
	}, []);

	const value = useMemo(() => ({
		imageshareActive,
		imageshareActiveTracks,
		imageshareData,
		imageshareMediastream,
		imageshareRequestError,
		requestImageshare,
		setImageshareRequestError,
		stopImageshare,
	}), [
		imageshareActive,
		imageshareActiveTracks,
		imageshareData,
		imageshareMediastream,
		imageshareRequestError,
		requestImageshare,
		setImageshareRequestError,
		stopImageshare,
	]);

	return (
		<MediaShareImageContext.Provider value={value}>
			{children}
		</MediaShareImageContext.Provider>
	);
};
