// @ts-check

import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import * as mixing from '../../api/ws/mixing';
import { useReactVideo } from './Provider';
import { useDrawer } from '../PlayerLive/PlayerCanvasDrawerProvider';
import { useMediaKey } from '../Media/MediaKey/Provider';
import { usePipLayout } from '../ChannelButtonsPanel/PipLayoutProvider';
import { useSourceParticipantOffers } from '../SourceParticipantOffers/Context';
import { getComputerId } from '../../lib/computerId';
import { useMediaAudioFilters } from '../Media/AudioFilters/Provider';
import { useSoup } from '../Soup/Context';

/**
 * @typedef {import('../../../../../webrtc-router/src/lib/mixer/Formatter')
 * 	.ChannelMixerStatus} ChannelMixerStatus
 * @typedef {import('../../../../../webrtc-router/src/lib/mixer/Channel')
 * 	.SourceToCreate} SourceToCreate
 * @typedef {import('../Media/AudioFilters/Provider')
 * 	.AudioFiltersConfig} AudioFiltersConfig
 * */

/**
* @typedef {{
* 	showLogo?: boolean,
* 	showQrCode?: boolean,
* 	customLogoAsset?: { id: string, type: string, label: string, filename: string },
* 	customQrCodeAsset?: { id: string, type: string, label: string, filename: string },
* 	forcedPositions?: { logo: number, qrcode: number },
* 	isLogoFormatForced?: boolean,
* }} NotifyCanvasLogoChangeParams
*/

// TODO : finish typing
/**
 * @typedef {{
 * channelMixerStatus: ChannelMixerStatus | undefined,
 * setSource: (source: SourceToCreate, priorityLayer?: string | null) => Promise<void>,
 * clearLayer: (priorityLayer?: string | null) => Promise<void>,
 * notifyHostMuteParticipantCam: (sourceId: string, isMuted: boolean) => Promise<void>,
 * setSourceFilters: (source: AudioFiltersConfig['source'], filters: AudioFiltersConfig['filters']) => Promise<void>,
 * setSourceVolume: (source: {sourceId: string, type: string}, volume: number) => Promise<void>,
 * setSourceMuted: (source: {sourceId: string, type: string}, muted: boolean) => Promise<void>,
 * notifyCanvasLogoChange: (params: NotifyCanvasLogoChangeParams) => Promise<void>,
* }} IMixingContext
*/

export const MixingContext = createContext(
	/** @type {IMixingContext | undefined} */(undefined),
);

export const useMixing = () => {
	const mixingContext = useContext(MixingContext);
	// type guard (removes undefined type)
	if (!mixingContext) {
		throw new Error('useMixing must be used within a MixingProvider');
	}
	return mixingContext;
};

export const MIXING_CONNECTING_STATE_CONNECTED = 'MIXING_CONNECTING_STATE_CONNECTED';
export const MIXING_CONNECTING_STATE_DISCONNECTED = 'MIXING_CONNECTING_STATE_DISCONNECTED';
export const MIXING_CONNECTING_STATE_PENDING = 'MIXING_CONNECTING_STATE_PENDING';

/**
 * @typedef {{
 * 	children: React.ReactNode,
 * 	hashtag: string,
 * }} MixingProviderProps
 */

export const MixingProvider = (
	/** @type {MixingProviderProps} */
	{
		children,
		hashtag,
	},
) => {
	const {
		isController,
		getConnectionConfig,
		roomId,
		user,
	} = useReactVideo();
	const { join: joinSoup, soupSession } = useSoup();
	const { setIsDrawEnabled } = useDrawer();
	const [channelMixerStatus, setChannelMixerStatus] = useState(
		/** @type {ChannelMixerStatus | undefined} */(undefined),
	);
	const [connectingState, setConnectingState] = useState(MIXING_CONNECTING_STATE_DISCONNECTED);
	const [layer, setLayer] = useState('main');
	const isConnected = connectingState === MIXING_CONNECTING_STATE_CONNECTED;
	const { handleCMSEvents } = useMediaKey();
	const { setDisplayMode } = usePipLayout();
	const { handleCMSEvents: handleAudioFiltersCMSEvents } = useMediaAudioFilters();

	const join = useCallback(async (/** @type {() => boolean} */ isAborted) => {
		try {
			console.log('[joinMixing]');
			setConnectingState(MIXING_CONNECTING_STATE_PENDING);
			const connectionConfig = await getConnectionConfig();
			if (isAborted()) return;
			await mixing.connect(connectionConfig);
			if (isAborted()) return;
			await mixing.join(hashtag);
			if (isAborted()) return;
			setConnectingState(MIXING_CONNECTING_STATE_CONNECTED);
		} catch {
			setConnectingState(MIXING_CONNECTING_STATE_DISCONNECTED);
		}
	}, [getConnectionConfig, hashtag]);

	const leave = useCallback(async () => {
		setConnectingState(MIXING_CONNECTING_STATE_DISCONNECTED);
		console.log('[leaveMixing]');
		await mixing.disconnect();
	}, []);

	useEffect(() => {
		if (soupSession) {
			let aborted = false;
			const isAborted = () => aborted;
			join(isAborted);
			return () => {
				aborted = true;
				leave();
			};
		}
		return undefined;
	}, [soupSession, join, leave]);

	const clearLayer = useCallback(
		/** @type {IMixingContext['clearLayer']} */
		async (priorityLayer = null) => {
			console.log('clearLayer', hashtag, (priorityLayer ?? layer));
			return mixing.clearLayer(hashtag, (priorityLayer ?? layer));
		},
		[hashtag, layer],
	);

	const setSource = useCallback(
		/** @type {IMixingContext['setSource']} */
		async (source, priorityLayer = null) => {
			console.log('setSource', hashtag, source, (priorityLayer ?? layer));
			return mixing.setSource(hashtag, source, (priorityLayer ?? layer));
		},
		[hashtag, layer],
	);

	const notifyNewDrawing = useCallback(async (drawing) => {
		console.log('setDrawing', hashtag, { drawing });
		return mixing.notifyNewDrawing(hashtag, drawing);
	}, [hashtag]);

	const notifyClearDrawing = useCallback(async () => {
		console.log('clearDrawing', hashtag);
		return mixing.notifyClearDrawing(hashtag);
	}, [hashtag]);

	const notifyClearAllDrawings = useCallback(async () => {
		console.log('clearAllDrawings', hashtag);
		return mixing.notifyClearAllDrawings(hashtag);
	}, [hashtag]);

	const notifyToggleDrawings = useCallback(async (allowedUser) => {
		console.log('toggleDrawings', hashtag, allowedUser);
		return mixing.notifyToggleDrawings(hashtag, allowedUser);
	}, [hashtag]);

	const notifyToggleDrawingsBackground = useCallback(async (data) => {
		console.log('toggleDrawingsBackground', hashtag, data);
		return mixing.notifyToggleDrawingsBackground(hashtag, data);
	}, [hashtag]);

	const notifyKeyConfig = useCallback(async (config) => {
		console.log('keyConfig', { hashtag, config });
		return mixing.notifyKeyConfig(hashtag, config);
	}, [hashtag]);

	const setSourceMuted = useCallback(async (source, muted) => {
		console.log('setSourceMuted', hashtag, source, muted);
		return mixing.setSourceMuted(hashtag, source, muted);
	}, [hashtag]);

	const setSourceVolume = useCallback(async (source, volume) => {
		console.log('setSourceVolume', hashtag, source, volume);
		return mixing.setSourceVolume(hashtag, source, volume);
	}, [hashtag]);

	const setSourceTimecodes = useCallback(async (source, timecodes) => {
		console.log('setSourceTimecodes', hashtag, source, timecodes);
		return mixing.setSourceTimecodes(hashtag, source, timecodes);
	}, [hashtag]);

	const setSourceProgresstime = useCallback(async (source, progresstime) => {
		console.log('setSourceProgresstime', hashtag, source, progresstime);
		return mixing.setSourceProgresstime(hashtag, source, progresstime);
	}, [hashtag]);

	const setSourceSpeed = useCallback(async (source, speed) => {
		console.log('setSourceSpeed', hashtag, source, speed);
		return mixing.setSourceSpeed(hashtag, source, speed);
	}, [hashtag]);

	const notifyTogglePlayPauseVideoSource = useCallback(async (source) => {
		console.log('notifyTogglePlayPauseVideoSource', { hashtag, source });
		return mixing.togglePlayPauseVideoSource(hashtag, source);
	}, [hashtag]);

	const notifyToggleLoopVideoSource = useCallback(async (source) => {
		console.log('notifyToggleLoopVideoSource', { hashtag, source });
		return mixing.toggleLoopVideoSource(hashtag, source);
	}, [hashtag]);

	const changePiPPosition = useCallback(async ({ x, y, pipLayer, width, height }) => {
		console.log('setPiPPosition', { hashtag, x, y, pipLayer, width, height });
		return mixing.changePiPPosition(hashtag, x, y, pipLayer, width, height);
	}, [hashtag]);

	const notifyModeChange = useCallback(async (mode) => {
		console.log('notifyModeChange', { hashtag, mode });
		return mixing.publishModeChange(hashtag, mode);
	}, [hashtag]);

	const notifyAutomaticSwitchChange = useCallback(async (isEnabled) => {
		console.log('notifyAutomaticSwitchChange', { hashtag, isEnabled });
		return mixing.publishAutomaticSwitchChange(hashtag, isEnabled);
	}, [hashtag]);

	const notifyCrop = useCallback(async (rect) => {
		console.log('notifyCrop', { hashtag, rect });
		return mixing.notifyCrop(hashtag, rect);
	}, [hashtag]);

	const notifyDrawNickname = useCallback(async (isEnabled) => {
		console.log('notifyDrawNickname', { hashtag, isEnabled });
		return mixing.notifyDrawNickname(hashtag, isEnabled);
	}, [hashtag]);

	const notifyPipMode = useCallback(async (mode) => {
		console.log('notifyPipMode', { hashtag, mode });
		return mixing.notifyPipMode(hashtag, mode);
	}, [hashtag]);

	const updateGfx = useCallback(async (gfx) => {
		console.log('updateGfx', { hashtag, gfx });
		return mixing.publishGfxUpdate(hashtag, gfx);
	}, [hashtag]);

	const updateText = useCallback(async (text) => {
		console.log('updateText', { hashtag, text });
		return mixing.publishTextUpdate(hashtag, text);
	}, [hashtag]);

	const notifySceneApply = useCallback(async (scene) => {
		console.log('notifySceneApply', { hashtag, scene });
		return mixing.publishSceneApply(hashtag, scene);
	}, [hashtag]);

	const notifyAutomaticFillChange = useCallback(async (isEnabled) => {
		console.log('notifyAutomaticFillChange', { hashtag, isEnabled });
		return mixing.publishAutomaticFillChange(hashtag, isEnabled);
	}, [hashtag]);

	/**
	 * @param {NotifyCanvasLogoChangeParams} params
	 * @returns {Promise<void>}
	 */
	const notifyCanvasLogoChange = useCallback(async (
		/** @type {NotifyCanvasLogoChangeParams} */{
			showLogo,
			showQrCode,
			customLogoAsset,
			customQrCodeAsset,
			forcedPositions,
			isLogoFormatForced,
		},
	) => {
		console.log('notifyCanvasLogoChange', { hashtag, showLogo, showQrCode, customLogoAsset, customQrCodeAsset, forcedPositions, isLogoFormatForced });
		return mixing.notifyCanvasLogoChange(
			hashtag,
			{
				showLogo,
				showQrCode,
				customLogoAsset,
				customQrCodeAsset,
				forcedPositions,
				isLogoFormatForced,
			},
		);
	}, [hashtag]);

	const notifySourcesShapeChange = useCallback(async (sourceShape) => {
		console.log('notifySourcesShapeChange', { hashtag, sourceShape });
		return mixing.notifySourcesShapeChange(hashtag, sourceShape);
	}, [hashtag]);

	const notifySourceParticipantOffersChange = useCallback(async (sources) => {
		console.log('notifySourceParticipantOffersChange', { hashtag, sources });
		return mixing.notifySourceParticipantOffersChange(hashtag, sources);
	}, [hashtag]);

	const setSourceFilters = useCallback(async (source, filters) => {
		console.log('setSourceFilters', hashtag, source, filters);
		return mixing.setSourceAudioFilters(hashtag, source, filters);
	}, [hashtag]);

	const notifyHostMuteParticipantCam = useCallback(async (sourceId, isMuted) => {
		console.log('onHostMuteParticipantCam', { hashtag, sourceId, isMuted });
		return mixing.onHostMuteParticipantCam(hashtag, sourceId, isMuted);
	}, [hashtag]);

	const startRecording = useCallback(
		/**
		 * @param {number} durationInMinutes
		 * @returns {Promise<void>}
		 */
		async (durationInMinutes) => {
			console.log('startRecording', { hashtag, durationInMinutes });
			return mixing.startRecording(hashtag, durationInMinutes);
		}, [hashtag],
	);

	const stopRecording = useCallback(
		/**
		 * @returns {Promise<void>}
		 */
		async () => {
			console.log('stopRecording', { hashtag });
			return mixing.stopRecording(hashtag);
		}, [hashtag],
	);

	const { sources } = useSourceParticipantOffers();

	const notifyMixerSourceParticipantOffersChange = useCallback(() => {
		if (!isConnected) {
			return;
		}
		notifySourceParticipantOffersChange(
			sources.map((source) => ({
				configId: source.configId,
				computerId: getComputerId(),
				devices: source.devices.map((device) => ({
					deviceId: device.deviceId,
					disabled: device.disabled,
					label: device.label,
					kind: device.kind,
				})),
				id: source.id,
				label: source.label,
				subType: source.subType,
				user: {
					avatar: user.picture,
					nickname: user.preferred_username,
					userId: user.sub,
				},
			})),
		);
	}, [
		isConnected,
		notifySourceParticipantOffersChange,
		sources,
		user.picture,
		user.preferred_username,
		user.sub,
	]);

	useEffect(() => {
		notifyMixerSourceParticipantOffersChange();
	}, [notifyMixerSourceParticipantOffersChange]);

	const notifyMixerSourceParticipantOffersChangeRef = useRef(
		notifyMixerSourceParticipantOffersChange,
	);
	notifyMixerSourceParticipantOffersChangeRef.current = notifyMixerSourceParticipantOffersChange;

	useEffect(() => {
		if (isConnected) {
			const offEventStatus = mixing.onEventStatus((
				/** @type {ChannelMixerStatus} */ cms,
			) => {
				if (cms.initial) {
					console.log('INITIAL');
					notifyMixerSourceParticipantOffersChangeRef.current();
				}
			});

			return () => {
				offEventStatus();
			};
		}
		return undefined;
	}, [
		isConnected,
	]);

	useEffect(() => {
		if (isConnected) {
			const offEventStatus = mixing.onEventStatus((
				/** @type {ChannelMixerStatus} */ cms,
			) => {
				console.log('onEventStatus', cms);
				setChannelMixerStatus(cms);
				const allowDraw = cms.allowedUserToDraw === user.sub || cms.allowedUserToDraw === 'all';
				setIsDrawEnabled(isController || allowDraw);
				handleCMSEvents(cms);
				handleAudioFiltersCMSEvents(cms);
				if (cms.displayMode) {
					setDisplayMode(cms.displayMode);
				}
			});

			mixing.status(hashtag); // Ask for initial mixer status

			return () => {
				setChannelMixerStatus(undefined);
				offEventStatus();
			};
		}
		return undefined;
	}, [
		isController,
		handleCMSEvents,
		handleAudioFiltersCMSEvents,
		hashtag,
		isConnected,
		setIsDrawEnabled,
		user,
		setDisplayMode,
	]);

	const mixerSources = useMemo(
		() => channelMixerStatus?.sources || [],
		[channelMixerStatus?.sources],
	);

	useEffect(() => {
		const sourcesDifferentRoomId = mixerSources
			.filter((source) => 'roomId' in source)
			.filter((source) => source.roomId !== roomId);
		if (sourcesDifferentRoomId.length < 1) {
			return;
		}
		const roomIds = Array.from(new Set(sourcesDifferentRoomId.map((source) => source.roomId)));
		joinSoup(roomIds);
	}, [roomId, mixerSources, joinSoup]);

	const contextValue = useMemo(() => ({
		channelMixerStatus,
		clearLayer,
		connectingState,
		isConnected,
		layer,
		setLayer,
		setSource,
		setSourceMuted,
		setSourceVolume,
		setSourceTimecodes,
		setSourceSpeed,
		setSourceProgresstime,
		notifyNewDrawing,
		notifyClearDrawing,
		notifyClearAllDrawings,
		notifyDrawNickname,
		notifyKeyConfig,
		notifyToggleDrawings,
		notifyToggleDrawingsBackground,
		changePiPPosition,
		notifyModeChange,
		notifyAutomaticSwitchChange,
		notifyCrop,
		notifyPipMode,
		notifyTogglePlayPauseVideoSource,
		notifyToggleLoopVideoSource,
		updateGfx,
		updateText,
		notifySceneApply,
		notifyAutomaticFillChange,
		notifyCanvasLogoChange,
		notifyHostMuteParticipantCam,
		notifySourcesShapeChange,
		setSourceFilters,
		notifySourceParticipantOffersChange,
		startRecording,
		stopRecording,
	}), [
		channelMixerStatus,
		clearLayer,
		connectingState,
		isConnected,
		layer,
		setLayer,
		setSource,
		setSourceMuted,
		setSourceVolume,
		setSourceTimecodes,
		setSourceSpeed,
		setSourceProgresstime,
		notifyNewDrawing,
		notifyClearDrawing,
		notifyClearAllDrawings,
		notifyDrawNickname,
		notifyKeyConfig,
		notifyToggleDrawings,
		notifyToggleDrawingsBackground,
		changePiPPosition,
		notifyModeChange,
		notifyAutomaticSwitchChange,
		notifyCrop,
		notifyPipMode,
		notifyTogglePlayPauseVideoSource,
		notifyToggleLoopVideoSource,
		updateGfx,
		updateText,
		notifySceneApply,
		notifyAutomaticFillChange,
		notifyCanvasLogoChange,
		notifyHostMuteParticipantCam,
		notifySourcesShapeChange,
		setSourceFilters,
		notifySourceParticipantOffersChange,
		startRecording,
		stopRecording,
	]);

	return (
		<MixingContext.Provider value={contextValue}>
			{children}
		</MixingContext.Provider>
	);
};

MixingProvider.propTypes = {
	children: PropTypes.node.isRequired,
	hashtag: PropTypes.string.isRequired,
};
