import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useCallback, useLayoutEffect, useRef, useState } from 'react';

import ScrollContainer from 'react-indiana-drag-scroll';
import Hexagon from '../../Hexagon/Hexagon';
import ChannelThumbnail from '../ChannelThumbnail/ChannelThumbnail';
import { ListTitle } from '../ItemList/ListTitle';
import { ChevronIcon, ChevronIconDirection } from './ChevronIcon/ChevronIcon';
import { ContentLoader } from '../../ContentLoader/ContentLoader';

import './ChannelListHexagon.scss';

const Placeholder = () => (
	<Hexagon className="ChannelThumbnail ChannelListHexagon__placeholder" size="none">
		<ContentLoader containerClassName="h-100 w-100 d-flex" />
	</Hexagon>
);

export const ChannelListHexagon = ({
	channels,
	className,
	forwardedRef,
	isLoading,
	placeholderLength,
	title,
	isOrganizationChannel,
	rows,
}) => {
	const scrollContainer = useRef();
	const [isLoaded, setIsLoaded] = useState(false);

	const [isScrollable, setIsScrollable] = useState(
		{ right: true, left: true },
	);

	// Because placeholder array has a length of 50, on dev enrivonment where there is
	// less than 50 channels, the scroll position won't be moved to the right place
	const channelsOrPlaceholder = isLoading ? Array.from({ length: placeholderLength }) : channels;

	const checkScroll = useCallback(() => {
		if (scrollContainer.current) {
			setIsScrollable({
				left: scrollContainer.current.scrollLeft > 0,
				right: (scrollContainer.current.scrollLeft + scrollContainer.current.clientWidth)
					< scrollContainer.current.scrollWidth,
			});
		}
	}, []);

	useLayoutEffect(() => {
		window.addEventListener('resize', checkScroll);
		checkScroll();
		return () => window.removeEventListener('resize', checkScroll);
	}, [checkScroll]);

	useLayoutEffect(() => {
		if (scrollContainer.current) {
			scrollContainer.current.scrollLeft = (scrollContainer.current.scrollWidth / 2)
			- (scrollContainer.current.clientWidth / 2);
			setIsLoaded(true);
		}
	}, []);

	const handleHorizontalScrollRight = () => {
		scrollContainer.current.scrollLeft += (scrollContainer.current.clientWidth);
	};

	const handleHorizontalScrollLeft = () => {
		scrollContainer.current.scrollLeft += (-scrollContainer.current.clientWidth);
	};

	const hexagonClassName = `ChannelListHexagon${rows}`;

	if (channelsOrPlaceholder.length === 0) return null;

	return (
		<section
			className={clsx('ChannelList ChannelListHexagonCommon size-lg', className, hexagonClassName)}
			ref={forwardedRef}
		>
			{title && <span className="d-inline-block ml-5"><ListTitle title={title} /></span>}
			<ScrollContainer
				className={clsx('ChannelListHexagon__scrollContainer content-white py-2', { isLoaded })}
				innerRef={scrollContainer}
				onEndScroll={checkScroll}
				vertical={false}
				key="ChannelScroll"
			>
				<div className="ChannelListHexagon_list py-2">
					{
						channelsOrPlaceholder.map((channel, index) => (
							channel ? (
								<ChannelThumbnail
									channel={channel}
									key={channel.hashtag}
									isOrganizationChannel={isOrganizationChannel}
								/>
							// eslint-disable-next-line react/no-array-index-key
							) : <Placeholder key={index} />
						))
					}
				</div>
			</ScrollContainer>
			{
				isScrollable.left && (
					<span
						className="ChannelList_chevron_left pl-3 color-yellow"
						onClick={handleHorizontalScrollLeft}
					>
						<div className="position-absolute start-100 translate-middle">
							<ChevronIcon />
						</div>
					</span>
				)
			}
			{
				isScrollable.right && (
					<span
						className="ChannelList_chevron_right pr-3 color-yellow"
						onClick={handleHorizontalScrollRight}
					>
						<div className="position-absolute translate-middle">
							<ChevronIcon direction={ChevronIconDirection.RIGHT} />
						</div>
					</span>
				)
			}
		</section>
	);
};

ChannelListHexagon.propTypes = {
	channels: PropTypes.arrayOf(ChannelThumbnail.propTypes.channel),
	className: PropTypes.string,
	forwardedRef: PropTypes.shape({}),
	isLoading: PropTypes.bool,
	placeholderLength: PropTypes.number,
	title: PropTypes.node,
	isOrganizationChannel: PropTypes.bool,
	rows: PropTypes.number,
};

ChannelListHexagon.defaultProps = {
	channels: [],
	className: '',
	forwardedRef: null,
	isLoading: false,
	placeholderLength: 50,
	title: null,
	isOrganizationChannel: false,
	rows: 3,
};
