// @ts-check
import { useEffect } from 'react';

import { useMediaAudioFilters } from './Provider';

/**
 * @import {
 * 	LocalMediaStreamTrack, MediaAudioTrackControllable,
 * } from '../Tracks/Controllable/types';
 * @import { AudioFiltersConfig } from './Provider';
 */

const updateAudioFilters = (
	/** @type {MediaAudioTrackControllable<LocalMediaStreamTrack>} */ track,
	/** @type {AudioFiltersConfig} */ config,
) => {
	if (track.panner) {
		track.panner.pan.value = config.filters.balance;
	}

	if (track.equalizers) {
		track.equalizers.forEach((equalizer) => {
			const relatedConfig = config.filters.equalizer.find(({ freq }) => (
				freq === equalizer.frequency.value
			));
			if (relatedConfig) {
				equalizer.gain.value = relatedConfig.value;
			}
		});
	}
};

/**
 * @template {LocalMediaStreamTrack} T
 * @param {MediaAudioTrackControllable<T>[]} audioTracks
 */
export const useMediaAudioFiltersPerformer = (audioTracks) => {
	const { audioFiltersConfig } = useMediaAudioFilters();

	useEffect(() => {
		audioTracks.forEach((track) => {
			const config = audioFiltersConfig.find(({ source }) => source.id === track.sourceOffer?.id);
			if (config) updateAudioFilters(track, config);
		});
	}, [
		audioTracks,
		audioFiltersConfig,
	]);
};
