// @ts-check

import { useMediaAudioTracksControllable } from '../Controllable/useMediaAudioTracksControllable';
import { useMediaAudioFiltersPerformer } from '../../AudioFilters/useMediaAudioFiltersPerformer';

/**
 * @import { LocalMediaStreamTrack, MediaAudioTrackControllable } from '../Controllable/types';
 */

/**
 * @template {LocalMediaStreamTrack} T
 * @param {T[]} tracks
 * @returns {MediaAudioTrackControllable<T>[]}
 */
export const useMediaAudioTracksPerformers = (tracks) => {
	const controllableTracks = useMediaAudioTracksControllable(tracks);
	useMediaAudioFiltersPerformer(controllableTracks);

	return controllableTracks;
};
