// @ts-check

import { createContext, useContext } from 'react';

/** @enum {typeof ListSortingType[keyof typeof ListSortingType]} */
export const ListSortingType = /** @type {const} */({
	NEWEST_TO_OLDEST: 'NEWEST_TO_OLDEST',
	OLDEST_TO_NEWEST: 'OLDEST_TO_NEWEST',
});

/**
 * @typedef {{
* 		currentSort: ListSortingType,
* 		setCurrentSort: React.Dispatch<React.SetStateAction<ListSortingType>>
* }} IListSortingContext
*/

export const ListSortingContext = createContext(
	/** @type {IListSortingContext | undefined} */(undefined),
);

export const useListSorting = () => {
	const listSortingContext = useContext(ListSortingContext);

	if (!listSortingContext) {
		throw new Error('useListSorting must be used within a ListSortingProvider');
	}
	return listSortingContext;
};
