import { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import RecordingState from '../RecordingState/RecordingState';
import { ChannelLoading } from '../ChannelLoading/Loading';
import { PlayerLive } from '../PlayerLive/PlayerLive';
import { PlayerLiveVuMeter } from '../PlayerLive/PlayerLiveVuMeter';
import { usePlayerLiveVuMeter } from '../PlayerLive/PlayerLiveVuMeterProvider';
import { usePublications, useSubscribeMediastream } from '../Soup/hooks';
import { useSoup } from '../Soup/Context';
import { useReactVideo } from '../ReactVideo/Provider';

export const ChannelPlayer = ({
	children,
	className,
	handleClickFullScreen,
	isFullscreen,
	safeZoneComponent,
	isHost,
}) => {
	const { roomId } = useReactVideo();
	const { soupSession } = useSoup();
	const { isEnabled } = usePlayerLiveVuMeter();

	const publications = usePublications({ roomId });
	const generalAudioPublication = useMemo(
		() => publications.find((publication) => (
			publication.appData.streamType === 'live'
			&& publication.kind === 'audio'
			&& !publication.appData.peerReceiver
		)),
		[publications],
	);
	const subscriptionRequests = useMemo(
		() => (generalAudioPublication
			? ([{ publication: generalAudioPublication }])
			: []),
		[generalAudioPublication],
	);
	const generalAudioMediaStream = useSubscribeMediastream(subscriptionRequests);

	return (
		<ChannelLoading loading={!soupSession}>
			<div
				className={clsx(className, 'position-relative d-flex flex-column justify-content-center overflow-hidden align-items-center h-100 w-100')}
				onDoubleClick={handleClickFullScreen}
			>
				<RecordingState />
				<PlayerLive
					handleClickFullScreen={handleClickFullScreen}
					// TODO : isCameraOnMainLayer={!!liveMediaStream?.stream?.sources?.some((source) => isCamStreamId(source.streamId) && source.layer === 'main')}
					isFullscreen={isFullscreen}
					safeZoneComponent={safeZoneComponent}
				>
					{children}
				</PlayerLive>
				{isEnabled && isHost && (
					<PlayerLiveVuMeter
						className="position-absolute bottom-0 right-0 h-50 me-1"
						mediastream={generalAudioMediaStream}
					/>
				)}
			</div>
		</ChannelLoading>
	);
};

ChannelPlayer.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	handleClickFullScreen: PropTypes.func.isRequired,
	isFullscreen: PropTypes.bool,
	safeZoneComponent: PropTypes.elementType,
	isHost: PropTypes.bool,
};

ChannelPlayer.defaultProps = {
	children: null,
	className: '',
	isFullscreen: false,
	safeZoneComponent: null,
	isHost: false,
};
