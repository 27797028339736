// @ts-check

import { useCallback, useMemo, useState } from 'react';
import { useMediaAudioTracksPerformers } from '../Performers/useMediaAudioTracksPerformers';

/**
 * @import { LocalMediaStreamTrack, MediaAudioTrackControllable } from '../Controllable/types';
 */

const isDifferentTrack = (
	/** @type {LocalMediaStreamTrack | MediaAudioTrackControllable<LocalMediaStreamTrack>} */t1,
) => (
	/** @type {LocalMediaStreamTrack | MediaAudioTrackControllable<LocalMediaStreamTrack>} */t2,
) => (
	!(t1.id === t2.id
	|| ('originalTrackId' in t2 && t1.id === t2.originalTrackId)
	|| ('originalTrackId' in t1 && t2.id === t1.originalTrackId))
);

/**
 * @template {LocalMediaStreamTrack} T
 * @typedef {{
*  addTrack: (track: T) => void,
*  addTracks: (tracks: T[]) => void,
*  clearTracks: () => void,
*  removeTrack: (track: T | MediaAudioTrackControllable<T>) => void,
*  tracks: (T | MediaAudioTrackControllable<T>)[],
* }} MediaTracksManager<T>
*/

/**
* @template {LocalMediaStreamTrack} T
* @returns {MediaTracksManager<T>}
*/
export const useMediaTracksManager = () => {
	const [originalTracks, setOriginalTracks] = useState(
		/** @type {T[]} */([]),
	);

	const audioTracks = useMemo(() => originalTracks.filter((track) => track.kind === 'audio'), [originalTracks]);
	const managedAudioTracks = useMediaAudioTracksPerformers(audioTracks);

	const videoTracks = useMemo(() => originalTracks.filter((track) => track.kind === 'video'), [originalTracks]);

	const managedTracks = useMemo(() => [
		...managedAudioTracks,
		...videoTracks,
	], [
		managedAudioTracks,
		videoTracks,
	]);

	const addTracks = useCallback(
		/** @type {MediaTracksManager<T>['addTracks']} */
		(newTracks) => {
			setOriginalTracks((currentTracks) => [
				...currentTracks.filter((currentTrack) => newTracks.every(isDifferentTrack(currentTrack))),
				...newTracks,
			]);
		}, [],
	);

	const addTrack = useCallback(
		/** @type {MediaTracksManager<T>['addTrack']} */
		(track) => {
			addTracks([track]);
		}, [addTracks],
	);

	const removeTrack = useCallback(
		/** @type {MediaTracksManager<T>['removeTrack']} */
		(oldTrack) => {
			setOriginalTracks((currentTracks) => currentTracks
				.filter(isDifferentTrack(oldTrack)));
		}, [],
	);

	const clearTracks = useCallback(
		/** @type {MediaTracksManager<T>['clearTracks']} */
		() => {
			setOriginalTracks((prev) => (prev.length < 1 ? prev : []));
		}, [],
	);

	return useMemo(() => ({
		addTrack,
		addTracks,
		clearTracks,
		removeTrack,
		tracks: managedTracks,
	}), [
		addTrack,
		addTracks,
		clearTracks,
		removeTrack,
		managedTracks,
	]);
};
