// @ts-check

import { api } from '../api';

export const API_VOTE_PATH = '/channel/vote';
export const API_VOTE_TEMPLATE_PATH = '/channel/vote/template';

/**
 * @import { AxiosResponse } from 'axios';
 * @import { IVoteDto, IVotePublishDto, IVoteTemplateDto } from './vote.dto';
 * @import { VoteReportRecipientType } from '../../components/Vote/Context';
 * @import { PaginatedResult } from '../helpers';
 */

/**
 * @param {string} studioId
 * @returns {Promise<AxiosResponse<IVoteDto[]>>}
 */
export const fetchChannelVotesByStudioId = (studioId) => api.get(
	`${API_VOTE_PATH}/history/${studioId}`,
);

/**
 * @param {string} studioId
 * @returns {Promise<AxiosResponse<IVotePublishDto[]>>}
 */
export const fetchReport = (studioId) => api.get(
	`${API_VOTE_PATH}/report/${studioId}`,
);

export const fetchVoteReportVoteDetail = (voteId) => api.get(
	`${API_VOTE_PATH}/report/vote/${voteId}`,
);

export const fetchVoteReportUserDetail = (studioId, userId) => api.get(
	`${API_VOTE_PATH}/report/studio/${studioId}/user/${userId}`,
);

export const fetchReportCSV = (studioId, audienceType) => api.get(
	`${API_VOTE_PATH}/report/${studioId}/${audienceType}/csv`,
);

/**
 * @param {string} studioId
 * @param {{
 *  title: string,
 *  category?: string,
 *  duration: number,
 *  position: IVoteDto['position'],
 *  options: { label: string, correct?: boolean }[],
 *  templateId?: string,
 *  audienceType: IVoteDto['audienceType'],
 * }[]} votes
 * @param {{
 * 	audienceType?: IVoteDto['audienceType'],
 *  position?: IVoteDto['position'],
 *  duration?: number,
 * }} optionalParameters
 * @returns {Promise<AxiosResponse<void>>}
 */
export const createVotes = (studioId, votes, optionalParameters) => api.post(
	`${API_VOTE_PATH}/${studioId}`,
	{ votes, ...optionalParameters },
);

/**
 * @param {string} voteId
 * @returns {Promise<AxiosResponse<void>>}
 */
export const endVote = (voteId) => api.put(
	`${API_VOTE_PATH}/${voteId}/end`,
);

/**
 * @param {string} voteId
 * @param {string} answerId
 * @returns {Promise<AxiosResponse<void>>}
 */
export const voteOnVote = (voteId, answerId) => api.put(
	`${API_VOTE_PATH}/${voteId}/vote/${answerId}`,
);

/**
 * @param {string} voteId
 * @returns {Promise<AxiosResponse<void>>}
 */
export const publishVote = (voteId) => api.put(
	`${API_VOTE_PATH}/${voteId}/publish`,
);

/**
 * @param {string} voteId
 * @returns {Promise<AxiosResponse<void>>}
 */
export const endPublishVote = (voteId) => api.delete(
	`${API_VOTE_PATH}/${voteId}/publish/end`,
);

/**
 * @param {string} voteId
 * @param {IVoteDto['position']} position
 * @returns {Promise<AxiosResponse<void>>}
 */
export const repositionVote = (voteId, position) => api.put(
	`${API_VOTE_PATH}/${voteId}/reposition`,
	{ position },
);

/**
 * @param {boolean} isArchived
 * @param {number} itemsPerPage
 * @param {number} currentPage
 * @param {number} sortValue
 * @returns {Promise<AxiosResponse<PaginatedResult<IVoteTemplateDto>>>}
 */
export const fetchChannelVoteTemplates = (
	isArchived,
	itemsPerPage,
	currentPage,
	sortValue,
) => api.get(
	`${API_VOTE_TEMPLATE_PATH}`,
	{
		params: {
			isArchived,
			itemsPerPage,
			currentPage,
			sortValue,
		},
	},
);

/**
 * @param {{
 * 	title: string,
 *  category?: string,
 *  duration: number,
 *  position: IVoteDto['position'],
 *  options: { label: string, correct?: boolean }[],
 *  audienceType?: IVoteDto['audienceType'],
 * }} voteTemplate
 * @returns {Promise<AxiosResponse<void>>}
 */
export const createVoteTemplate = (voteTemplate) => api.post(
	`${API_VOTE_TEMPLATE_PATH}`,
	voteTemplate,
);

/**
 * @param {string} voteTemplateId
 * @returns {Promise<AxiosResponse<void>>}
 */
export const deleteVoteTemplate = (voteTemplateId) => api.delete(`${API_VOTE_TEMPLATE_PATH}/${voteTemplateId}`);

/**
 * @param {string} voteTemplateId
 * @returns {Promise<AxiosResponse<void>>}
 */
export const restoreVoteTemplate = (voteTemplateId) => api.put(`${API_VOTE_TEMPLATE_PATH}/restore/${voteTemplateId}`);

/**
 * @param {string} studioId
 * @param {{
 *  sendTo: Record<VoteReportRecipientType, boolean>,
 *  votes: string[],
 * }} content
 * @returns {Promise<AxiosResponse<void>>}
 */
export const sendReportThroughMail = (studioId, content) => api.post(
	`${API_VOTE_PATH}/report/email/${studioId}`,
	content,
);
