import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef } from 'react';
import {
	connectChannelSockets,
	disconnectChannelSockets,
	joinChannel,
	onEventPostDelete,
	onEventPostLikeChange,
	onEventPostPinChange,
	onEventPostNew,
	onEventPostUpdate,
	onEventChatMessage,
	onEventChatMessageDelete,
	onEventChatMessagesClear,
	onEventChatMessageUpdate,
	onEventChatStatus,
	onEventConnectionAll,
	onEventConnectionLeft,
	onEventConnectionNew,
	onEventStudioStatus,
	onEventAdLaunch,
	onEventAuction,
	onEventVotesActiveNew,
	onEventVoteActiveUpdate,
	onEventHandRaised,
	onEventModerationBan,
	onEventModerationUnban,
	onEventPostCommentNew,
	onEventPostCommentDelete,
	onEventPostCommentUpdate,
	onEventPostCommentLikeChange,
	onEventVodNew,
} from '../../../api/ws/channel';
import { sessionId } from '../../../lib/session';
import { identity, useAuthentication } from '../../Authentication/Authentication';
import { useAd } from '../../Ad/Context';
import { useAuction } from '../../Auction/Context';
import { useChat } from '../Chat/Provider';
import { useStudio } from '../../Studio/Provider';
import { useVote } from '../../Vote/Context';
import { useChannelConnection } from './ChannelConnectionContext';
import { useHandRaising } from '../Guest/HandRaisingProvider';
import { ResourceAccessRole } from '../../../lib/ResourceAccessRole';
import { useModeration } from '../Moderation/Context';
import {
	useHandleDeletedPost,
	useHandlePostLikesChange,
	useHandleNewPost,
	useHandleUpdatedPost,
	useHandlePostPinnedChange,
} from '../../../api-hooks/channel/posts';
import {
	useHandleDeletePostComment,
	useHandleNewPostComment,
	useHandlePostCommentLikesChange,
	useHandleUpdatePostComment,
} from '../../../api-hooks/channel/comments';
import { useChannelWatch } from '../Watch/Context';

const execIfHandlerExists = (handler, ...args) => {
	if (handler) return handler(...args);
	return undefined;
};

export const ChannelSocketConnector = ({
	channelId,
	role,
	studioId,
	token,
	onWsError,
	organizationId,
}) => {
	const { handleEventAdLaunch } = useAd();
	const { handleEventAuction } = useAuction();
	const { handleEventVotesActiveNew, handleEventVoteActiveUpdate } = useVote();
	const { setGuestRaisedHand } = useHandRaising();
	const {
		handleEventModerationBan,
		handleEventModerationUnban,
	} = useModeration();
	const {
		handleEventChatMessage,
		handleEventChatMessageUpdate,
		handleEventChatMessageDelete,
		handleEventChatMessagesClear,
		handleEventChatStatus,
	} = useChat();
	const {
		handleEventConnectionNew,
		handleEventConnectionLeft,
		handleEventConnectionAll,
	} = useChannelConnection();
	const { handleEventVodNew } = useChannelWatch();
	const { handleEventStudioStatus } = useStudio();
	const { isLoggedIn } = useAuthentication();

	const handleNewPost = useHandleNewPost(channelId);
	const handleUpdatedPost = useHandleUpdatedPost(channelId);
	const handleDeletedPost = useHandleDeletedPost(channelId);
	const handleLikedPost = useHandlePostLikesChange(channelId);
	const handlePinChange = useHandlePostPinnedChange(channelId);

	const handleNewPostComment = useHandleNewPostComment(channelId);
	const handleDeletePostComment = useHandleDeletePostComment(channelId);
	const handleUpdatePostComment = useHandleUpdatePostComment();
	const handleLikedPostComment = useHandlePostCommentLikesChange();

	const handlersRef = useRef();
	useEffect(() => {
		handlersRef.current = {
			handleEventAdLaunch,
			handleEventAuction,
			handleEventVotesActiveNew,
			handleEventVoteActiveUpdate,
			setGuestRaisedHand,
			handleEventModerationBan,
			handleEventModerationUnban,
			handleEventChatMessage,
			handleEventChatMessageUpdate,
			handleEventChatMessageDelete,
			handleEventChatMessagesClear,
			handleEventChatStatus,
			handleEventConnectionNew,
			handleEventConnectionLeft,
			handleEventConnectionAll,
			handleEventStudioStatus,
			handleNewPost,
			handleUpdatedPost,
			handleDeletedPost,
			handleLikedPost,
			handlePinChange,
			handleNewPostComment,
			handleDeletePostComment,
			handleUpdatePostComment,
			handleLikedPostComment,
			handleEventVodNew,
		};
	}, [
		handleEventAdLaunch,
		handleEventAuction,
		handleEventVotesActiveNew,
		handleEventVoteActiveUpdate,
		setGuestRaisedHand,
		handleEventModerationBan,
		handleEventModerationUnban,
		handleEventChatMessage,
		handleEventChatMessageUpdate,
		handleEventChatMessageDelete,
		handleEventChatMessagesClear,
		handleEventChatStatus,
		handleEventConnectionNew,
		handleEventConnectionLeft,
		handleEventConnectionAll,
		handleEventStudioStatus,
		handleNewPost,
		handleUpdatedPost,
		handleDeletedPost,
		handleLikedPost,
		handlePinChange,
		handleNewPostComment,
		handleDeletePostComment,
		handleUpdatePostComment,
		handleLikedPostComment,
		handleEventVodNew,
	]);

	const offAllEvents = useRef([]);
	const leaveChannel = useRef(() => {});

	const subscribeChannel = useCallback(async () => {
		let socketTocken = token;
		if (!socketTocken) {
			try {
				const { token: accessToken } = await identity.getAccessToken();
				socketTocken = accessToken;
			} catch (error) { /* empty */ }
		}

		try {
			await connectChannelSockets({
				onError: onWsError,
				params: {
					sessionId,
					token: socketTocken,
					organizationId: token ? undefined : organizationId,
				},
			});
		} catch (error) {
			if (onWsError) {
				onWsError(error);
				return;
			}
			throw error;
		}

		leaveChannel.current = joinChannel(
			channelId,
			role,
			studioId,
		);

		offAllEvents.current = [
			onEventModerationBan((...args) => execIfHandlerExists(
				handlersRef.current.handleEventModerationBan,
				...args,
			)),
			onEventModerationUnban((...args) => execIfHandlerExists(
				handlersRef.current.handleEventModerationUnban,
				...args,
			)),
			onEventAdLaunch((...args) => execIfHandlerExists(
				handlersRef.current.handleEventAdLaunch,
				...args,
			)),
			onEventAuction((...args) => execIfHandlerExists(
				handlersRef.current.handleEventAuction,
				...args,
			)),
			onEventVotesActiveNew((...args) => execIfHandlerExists(
				handlersRef.current.handleEventVotesActiveNew,
				...args,
			)),
			onEventVoteActiveUpdate((...args) => execIfHandlerExists(
				handlersRef.current.handleEventVoteActiveUpdate,
				...args,
			)),
			onEventChatMessage((...args) => execIfHandlerExists(
				handlersRef.current.handleEventChatMessage,
				...args,
			)),
			onEventChatMessageUpdate((...args) => execIfHandlerExists(
				handlersRef.current.handleEventChatMessageUpdate,
				...args,
			)),
			onEventChatMessageDelete((...args) => execIfHandlerExists(
				handlersRef.current.handleEventChatMessageDelete,
				...args,
			)),
			onEventChatMessagesClear((...args) => execIfHandlerExists(
				handlersRef.current.handleEventChatMessagesClear,
				...args,
			)),
			onEventChatStatus((...args) => execIfHandlerExists(
				handlersRef.current.handleEventChatStatus,
				...args,
			)),
			onEventConnectionNew((...args) => execIfHandlerExists(
				handlersRef.current.handleEventConnectionNew,
				...args,
			)),
			onEventConnectionLeft((...args) => execIfHandlerExists(
				handlersRef.current.handleEventConnectionLeft,
				...args,
			)),
			onEventConnectionAll((...args) => execIfHandlerExists(
				handlersRef.current.handleEventConnectionAll,
				...args,
			)),
			onEventStudioStatus((...args) => execIfHandlerExists(
				handlersRef.current.handleEventStudioStatus,
				...args,
			)),
			onEventPostNew((...args) => execIfHandlerExists(
				handlersRef.current.handleNewPost,
				...args,
			)),
			onEventPostUpdate((...args) => execIfHandlerExists(
				handlersRef.current.handleUpdatedPost,
				...args,
			)),
			onEventPostDelete((...args) => execIfHandlerExists(
				handlersRef.current.handleDeletedPost,
				...args,
			)),
			onEventPostLikeChange((...args) => execIfHandlerExists(
				handlersRef.current.handleLikedPost,
				...args,
			)),
			onEventPostPinChange((...args) => execIfHandlerExists(
				handlersRef.current.handlePinChange,
				...args,
			)),
			onEventPostCommentNew((...args) => execIfHandlerExists(
				handlersRef.current.handleNewPostComment,
				...args,
			)),
			onEventPostCommentDelete((...args) => execIfHandlerExists(
				handlersRef.current.handleDeletePostComment,
				...args,
			)),
			onEventPostCommentUpdate((...args) => execIfHandlerExists(
				handlersRef.current.handleUpdatePostComment,
				...args,
			)),
			onEventPostCommentLikeChange((...args) => execIfHandlerExists(
				handlersRef.current.handleLikedPostComment,
				...args,
			)),
			onEventHandRaised((...args) => execIfHandlerExists(
				handlersRef.current.setGuestRaisedHand,
				...args,
			)),
			onEventVodNew((...args) => execIfHandlerExists(
				handlersRef.current.handleEventVodNew,
				...args,
			)),
		];
	}, [handlersRef, onWsError, channelId, role, studioId, token, organizationId]);

	const unsubscribeChannel = useCallback(() => {
		offAllEvents.current.forEach((offEvent) => offEvent());
		leaveChannel.current();

		/**
		 * BE SURE TO DISCONNECT SOCKET ON UNMOUNT AND RECONNECT
		 * TO GET A NEW SOCKETID.
		 * The socketId is used in api to count studio participants.
		 */
		disconnectChannelSockets();
	}, []);

	useEffect(() => {
		subscribeChannel();
		return unsubscribeChannel;
	}, [isLoggedIn, subscribeChannel, unsubscribeChannel]);

	return null;
};

ChannelSocketConnector.propTypes = {
	channelId: PropTypes.string.isRequired,
	role: PropTypes.oneOf(Object.values(ResourceAccessRole)),
	studioId: PropTypes.string,
	organizationId: PropTypes.string,
	onWsError: PropTypes.func,
	token: PropTypes.string,
};

ChannelSocketConnector.defaultProps = {
	role: ResourceAccessRole.PUBLIC,
	studioId: undefined,
	organizationId: undefined,
	onWsError: undefined,
	token: undefined,
};
