/* eslint-disable react/prop-types */
// @ts-check

import { useMemo, useState } from 'react';

import { ListSortingContext, ListSortingType } from './Context';

/**
 * @typedef {{
 * 		children: React.ReactNode,
 * 		defaultSort?: ListSortingType,
 * }} ListSortingProviderProps
 */

/** @type {React.FC<ListSortingProviderProps>} */
export const ListSortingProvider = ({
	children,
	defaultSort = ListSortingType.NEWEST_TO_OLDEST,
}) => {
	const [currentSort, setCurrentSort] = useState(defaultSort);

	const value = useMemo(() => ({
		currentSort,
		setCurrentSort,
	}), [
		currentSort,
		setCurrentSort,
	]);

	return (
		<ListSortingContext.Provider value={value}>
			{children}
		</ListSortingContext.Provider>
	);
};
